import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {EditorContent, useEditor} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import {formatTimeAgo} from '../../utils/formatTime.js';
import {Heading} from '@tiptap/extension-heading';
import {mergeAttributes} from '@tiptap/core';
import {TextAlign} from '@tiptap/extension-text-align';
import {Paragraph} from '@tiptap/extension-paragraph';
import {Link} from '@tiptap/extension-link';
import MetaComponent from '../../components/common/MetaComponent.jsx';
import {Image} from '@tiptap/extension-image';
import YouTube from '@tiptap/extension-youtube';
import {TextStyle} from '@tiptap/extension-text-style';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import {
    Accordion, AccordionBody, AccordionHeader,
    Card,
    CardBody,
    IconButton,
    Spinner,
    Typography
} from '@material-tailwind/react';
import {fetchArticle} from '../../services/articleService';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    XIcon,
    WhatsappShareButton,
    WhatsappIcon,
    EmailShareButton,
    EmailIcon
} from 'react-share';
import slugify from 'slugify';
import {DocumentTextIcon, PhotoIcon, VideoCameraIcon, ArrowDownTrayIcon} from '@heroicons/react/24/outline';
import axios from 'axios';
import fileDownload from 'js-file-download';
import ImageGallery from '../../components/image-gallery/ImageGallery';
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/24/outline';
import ArticlePageSideContent from './ArticlePageSideContent';

export default function ArticleContent({className}) {
    const {categorySlug, articleSlug} = useParams();
    const [metadata, setMetadata] = useState({});
    const [article, setArticle] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [content, setContent] = useState(null);
    const [headings, setHeadings] = useState([]);
    const [collapsedSections, setCollapsedSections] = useState({});
    const [contentsOpen, setContentsOpen] = useState(false);

    const navigate = useNavigate();

    const loadArticle = async () => {
        try {
            const data = await fetchArticle(categorySlug, articleSlug)
                .catch(error => navigate('/not-found', {replace: true}));

            setArticle(data);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (categorySlug && articleSlug) {
            loadArticle();
        }
    }, [articleSlug]);

    useEffect(() => {
        if (article) {
            setMetadata({
                title: `${article.title} - Balkanski Mozaik`,
                description: article.description || '',
                keywords: article.tags.join(', ') || '',
                image: article.coverImages[0]?.url || '',
                url: window.location.href
            });

            setContent(article.content);
        }
    }, [article]);

    const editor = useEditor({
        extensions: [
            StarterKit.configure({
                heading: false,
                paragraph: false,
                blockquote: {
                    HTMLAttributes: {
                        class: 'mb-2 sm:mb-4 md:mb-5 bg-blue-50 p-5 border-t-8 border-b-8 border-blue-800'
                    }
                }
            }),
            Heading.configure({levels: [1, 2, 3]}).extend({
                addAttributes() {
                    return {
                        ...this.parent?.(),
                        id: {
                            default: null,
                            renderHTML: attributes => {
                                return {id: attributes.id};
                            },
                            parseHTML: element => {
                                return {
                                    id: element.getAttribute('id')
                                };
                            }
                        }
                    };
                },
                addNodeView() {
                    return ({node}) => {
                        const textContent = node.textContent;
                        const id = slugify(textContent, {lower: true, strict: true});

                        node.attrs.id = id;

                        return this.parent?.();
                    };
                },
                levels: [2, 3],
                renderHTML({node, HTMLAttributes}) {
                    const level = this.options.levels.includes(node.attrs.level)
                        ? node.attrs.level
                        : this.options.levels[0];
                    const classes = {
                        2: 'text-xl md:text-2xl font-bold mb-2 sm:mb-4 md:mb-5',
                        3: 'text-lg md:text-xl font-bold mb-2 sm:mb-4 md:mb-5'
                    };
                    return [
                        `h${level}`,
                        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                            class: `${classes[level]}`,
                            id: node.attrs.id
                        }),
                        0
                    ];
                }
            }),
            Paragraph.extend({
                renderHTML({HTMLAttributes}) {
                    return [
                        'p',
                        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                            class: 'block text-gray-900 text-base sm:text-lg mb-2 sm:mb-4 md:mb-5'
                        }),
                        0
                    ];
                }
            }),
            TextStyle,
            Underline,
            Italic,
            TextAlign.configure({
                types: ['heading', 'paragraph']
            }).extend({
                addNodeView() {
                    return ({node, HTMLAttributes, view, getPos}) => {
                        const alignmentClasses = {
                            left: 'text-left',
                            center: 'text-center',
                            right: 'text-right',
                            justify: 'text-justify'
                        };
                        const align = node.attrs.textAlign || 'left';
                        return [
                            this.name,
                            mergeAttributes(HTMLAttributes, {class: alignmentClasses[align]}),
                            0
                        ];
                    };
                }
            }),
            Link.extend({
                addAttributes() {
                    return {
                        ...this.parent?.(),
                        target: {
                            default: null,
                            parseHTML: element => {
                                const href = element.getAttribute('href');
                                return href && href.startsWith('#') ? null : '_blank';
                            },
                            renderHTML: attributes => {
                                const href = attributes.href;
                                return href && href.startsWith('#') ? {} : {target: '_blank'};
                            }
                        },
                        rel: {
                            default: 'noopener noreferrer'
                        }
                    };
                },
                renderHTML({HTMLAttributes}) {
                    return ['a', mergeAttributes(HTMLAttributes, {class: 'font-semibold text-blue-600 hover:text-blue-900 translate-all'}), 0];
                }
            }),
            Image.configure({
                HTMLAttributes: {
                    class: 'rounded-lg'
                }
            }),
            YouTube.configure({
                HTMLAttributes: {
                    class: 'rounded-lg overflow-hidden',
                    modestBranding: true
                }
            })
        ],
        content,
        editable: false,
        editorProps: {
            attributes: {
                class: `prose max-w-none [&_ol]:list-decimal [&_ol]:pl-[revert] [&_ul]:list-disc [&_ul]:pl-[revert] ${className}`
            }
        },
        onUpdate: ({editor}) => {
            setContent(editor.getJSON());
        }
    });

    const sideContent = useMemo(() => (
        <ArticlePageSideContent className={'col-span-2'}/>
    ), []);

    useEffect(() => {
        if (editor && content) {
            editor.commands.setContent(content);
        }
    }, [content, editor]);

    useEffect(() => {
        if (editor && content) {
            editor.commands.setContent(content);

            const headingNodes = editor.view.state.doc.content.content
                .filter((node) => node.type.name === 'heading')
                .map((node) => ({
                    level: node.attrs.level,
                    text: node.textContent,
                    id: node.attrs.id
                }));
            setHeadings(headingNodes);
        }
    }, [content, editor]);

    if (isLoading) {
        return (
            <div className={`flex h-full xl:h-screen justify-center items-center ${className || ''}`}>
                <Spinner className={'h-10 w-10 md:h-12 md:w-12 lg:h-16 lg:w-16'} color={'red'}/>
            </div>
        );
    }

    const shareUrl = window.location.href;
    const shareTitle = article.title;

    const renderHeadings = (headings, level = 2) => {
        const result = [];
        let currentLevel = level;
        let children = [];

        headings.forEach((heading, index) => {
            if (heading.level === currentLevel) {
                if (children.length) {
                    result[result.length - 1].children = renderHeadings(children, currentLevel + 1);
                    children = [];
                }
                result.push({
                    ...heading,
                    children: []
                });
            } else if (heading.level > currentLevel) {
                children.push(heading);
            }
        });

        if (children.length && result.length) {
            result[result.length - 1].children = renderHeadings(children, currentLevel + 1);
        }

        return result;
    };

    const toggleCollapse = (id) => {
        setCollapsedSections(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const toggleAccordion = () => setContentsOpen(prevState => !prevState);

    const renderTOC = (headings) => (
        <ul className="font-normal text-gray-700 text-opacity-90 text-lg ">
            {headings.map((heading) => (
                <li key={heading.id}>
                    <a href={`#${heading.id}`} className="flex items-center gap-1.5 hover:bg-gray-200 px-4 py-0.5">
                        <span
                            className="text-lg text-gray-700 text-opacity-90 transition-colors duration-200 "
                        >
                            {heading.text}
                        </span>
                        {heading.children.length > 0 && (
                            <IconButton
                                variant={'text'}
                                size={'sm'}
                                className={'p-0'}
                                onClick={(event) => {
                                    event.preventDefault();
                                    toggleCollapse(heading.id)
                                }}
                            >
                                {collapsedSections[heading.id] ? (
                                    <ChevronUpIcon className="h-4 w-4 transition-transform duration-200 text-gray-800 text-opacity-90"/>
                                ) : (
                                    <ChevronDownIcon className="h-4 w-4 transition-transform duration-200 text-gray-800 text-opacity-90"/>
                                )}
                            </IconButton>
                        )}
                    </a>
                    {!collapsedSections[heading.id] && heading.children.length > 0 && (
                        <div className="pl-3">
                            {renderTOC(heading.children)}
                        </div>
                    )}
                </li>
            ))}
        </ul>
    );

    const nestedHeadings = renderHeadings(headings);

    const getFileIcon = (mimeType) => {
        if (mimeType.startsWith('image/')) {
            return <PhotoIcon className="h-5 w-5 text-gray-700"/>;
        } else if (mimeType.startsWith('video/')) {
            return <VideoCameraIcon className="h-5 w-5 text-gray-700"/>;
        } else {
            return <DocumentTextIcon className="h-5 w-5 text-gray-700"/>;
        }
    };

    const handleDownload = (file) => {
        axios.get(file.url, {
            responseType: 'blob'
        })
            .then((res) => {
                fileDownload(res.data, file.originalName);
            });
    };

    const HorizontalDivider = () => <span className={'w-0.5 h-2.5 mt-0.5 bg-gray-300 rounded hidden sm:block'}></span>;



    return (
        <>
            <MetaComponent meta={metadata}/>
            <div className={`${className || ''} pb-6`}>
                <h1 className={'text-2xl sm:text-3xl md:text-4xl font-bold'}>
                    {article.title}
                </h1>
                <div className={'flex flex-col gap-0.5 py-1'}>
                    <div
                        className={'flex flex-row flex-wrap sm:flex-nowrap mt-1 sm:mt-2 md:mt-4 mb-1 sm:mb-2.5 gap-x-2 gap-y-1.5 items-center'}>
            <span
                className={'font-semibold text-sm text-red-500 pr-2 w-full sm:w-auto my-auto cursor-pointer hover:text-red-600 transition-colors'}
                onClick={() => navigate(`/${article.category.slug}`, {replace: true})}
            >
              {article.category.name}
            </span>
                        <HorizontalDivider/>
                        <span className={'w-full sm:w-auto sm:px-2 text-gray-500 text-sm font-semibold'}>
                Autor:{' '}
                            <span className={'cursor-pointer text-gray-300 hover:text-gray-600 transition-colors'}>
                  {article.author.name} {article.author.lastname}
                </span>
              </span>
                        <HorizontalDivider/>
                        <span
                            className={'sm:px-2 w-full sm:w-auto text-gray-300 font-semibold text-sm'}>{formatTimeAgo(article.createdAt)}</span>
                    </div>
                    <div className={'flex gap-2.5 mb-2.5 mt-1'}>
                        <Typography className={'text-sm font-semibold text-gray-500'}>
                            Teilen:
                        </Typography>
                        <div className={'flex gap-5'}>
                            <FacebookShareButton url={shareUrl} quote={shareTitle}>
                                <FacebookIcon size={22} round bgStyle={{fill: '#484848'}}/>
                            </FacebookShareButton>
                            <TwitterShareButton url={shareUrl} title={shareTitle}>
                                <XIcon size={22} round bgStyle={{fill: '#484848'}}/>
                            </TwitterShareButton>
                            <WhatsappShareButton url={shareUrl} title={shareTitle}>
                                <WhatsappIcon size={22} round bgStyle={{fill: '#484848'}}/>
                            </WhatsappShareButton>
                            <EmailShareButton url={shareUrl} subject={shareTitle}
                                              body="Pročitaj ovaj članak sa portala Balkanski Mozaik!">
                                <EmailIcon size={22} round bgStyle={{fill: '#484848'}}/>
                            </EmailShareButton>
                        </div>
                    </div>
                </div>
                <div className={'flex flex-col xl:grid grid-cols-8 gap-6 xl:gap-4'}>
                    <div className={'col-span-6'}>
                        {
                            !!article &&
                            <ImageGallery images={article.coverImages} imageCreditTexts={article.coverImageCreditTexts}
                                          imageCreditLinks={article.coverImageCreditLinks}/>
                        }
                        {!!headings.length && (
                            <div
                                className={`w-full container mt-4 mb-6 flex flex-col gap-1.5 ${contentsOpen ? 'rounded-lg border border-gray-200' : ''}`}>
                                <Accordion open={contentsOpen}>
                                    <AccordionHeader
                                        onClick={toggleAccordion}
                                        className={`text-xl flex justify-between items-center pl-4 py-2 bg-gray-50 hover:bg-gray-100 ${contentsOpen ? '' : 'rounded-lg border border-gray-200'} rounded-t-lg`}
                                    >
                                        <span className={'text-gray-700 font-medium text-opacity-85'}>In diesem Artikel</span>
                                        <span className="ml-auto">
                                            {contentsOpen ? (
                                                    <ChevronUpIcon className="h-5 w-5 text-gray-500"/>)
                                                : (<ChevronDownIcon className="h-5 w-5 text-gray-500"/>
                                                )}
                                        </span>
                                    </AccordionHeader>
                                    <AccordionBody className="py-2 bg-gray-50 rounded-b-lg">
                                        <div className="text-gray-800 text-base leading-relaxed">
                                            {renderTOC(nestedHeadings)}
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                            </div>

                        )}

                        <div className={`flex flex-col gap-6 ${!headings.length ? 'mt-6' : ''}`}>
                            <EditorContent editor={editor}/>
                            {
                                article.files.length > 0 &&
                                <div className={''}>
                            <span className={'text-xl sm:text-3xl font-bold text-gray-500'}
                                  id={'attachments'}>Prilozi</span>
                                    <div className={'flex gap-2 flex-wrap mt-2'}>
                                        {article.files.map((file, index) => (
                                            <Card
                                                className={'border group hover:bg-gray-100 cursor-pointer shadow-none'}
                                                onClick={() => handleDownload(file)}
                                                key={index}>
                                                <CardBody
                                                    className={'px-3.5 py-2 flex flex-row gap-2 justify-between items-center'}>
                                                    {getFileIcon(file.mimeType)}
                                                    {file.originalName}
                                                    <ArrowDownTrayIcon
                                                        className="h-5 w-5 ml-2 text-gray-700 opacity-0 group-hover:opacity-100 transition-opacity"/>
                                                </CardBody>
                                            </Card>
                                        ))}
                                    </div>
                                </div>
                            }
                            {
                                article.tags.length > 0 &&
                                <div>
                                    <span className={'text-xl sm:text-3xl font-bold text-gray-500'}>Tags</span>
                                    <div className={'flex flex-wrap gap-2 mt-2'}>
                                        {article.tags.map((tag, index) => (
                                            <a href={`/search?q=${encodeURIComponent(tag.trim())}`} key={index}>
                                                <span key={index}
                                                    className={'inline-block bg-red-100 text-sm text-red-500 rounded-full px-5 py-2'}>
                                                    {tag}
                                                </span>
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                    {sideContent}
                </div>
            </div>
        </>
    );
}
