import React from 'react';
import MetaComponent from '../../components/common/MetaComponent';
import {Typography} from '@material-tailwind/react';

export default function PrivacyPolicy() {
  const metadata = {
    title: 'Datenschutzrichtlinie - Balkanski Mozaik'
  };

  return (
    <>
      <script>
        var UC_UI_SUPPRESS_CMP_DISPLAY=true;
      </script>
      <MetaComponent meta={metadata}/>
      <div className="container mx-auto pt-10 md:pt-12 lg:pt-16 pb-2 overflow-hidden">
        <Typography variant="h1" className="text-2xl md:text-3xl lg:text-4xl font-bold mb-6 md:mb-8 lg:mb-12">
          Datenschutzerklärung
        </Typography>
        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">1. Datenschutz auf einen Blick</Typography>
        <h3>Allgemeine Hinweise</h3> <p>Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber, was
        mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle
        Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen. Ausf&uuml;hrliche Informationen zum Thema
        Datenschutz entnehmen Sie unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.</p>
        <h3>Datenerfassung auf dieser Website</h3> <h4>Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser
        Website?</h4> <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen
        Kontaktdaten k&ouml;nnen Sie dem Abschnitt &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser
        Datenschutzerkl&auml;rung entnehmen.</p> <h4>Wie erfassen wir Ihre Daten?</h4> <p>Ihre Daten werden zum einen
        dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie in ein
        Kontaktformular eingeben.</p> <p>Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der
        Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.&nbsp;B. Internetbrowser,
        Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese
        Website betreten.</p> <h4>Wof&uuml;r nutzen wir Ihre Daten?</h4> <p>Ein Teil der Daten wird erhoben, um eine
        fehlerfreie Bereitstellung der Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur Analyse Ihres
        Nutzerverhaltens verwendet werden. Sofern &uuml;ber die Website Vertr&auml;ge geschlossen oder angebahnt werden
        k&ouml;nnen, werden die &uuml;bermittelten Daten auch f&uuml;r Vertragsangebote, Bestellungen oder sonstige
        Auftragsanfragen verarbeitet.</p> <h4>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h4> <p>Sie haben
        jederzeit das Recht, unentgeltlich Auskunft &uuml;ber Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten
        personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein Recht, die Berichtigung oder L&ouml;schung
        dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, k&ouml;nnen Sie diese
        Einwilligung jederzeit f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das Recht, unter bestimmten
        Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren
        steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.</p> <p>Hierzu sowie zu
        weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit an uns wenden.</p>
        <h3>Analyse-Tools und Tools von Dritt&shy;anbietern</h3> <p>Beim Besuch dieser Website kann Ihr Surf-Verhalten
        statistisch ausgewertet werden. Das geschieht vor allem mit sogenannten Analyseprogrammen.</p> <p>Detaillierte
        Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerkl&auml;rung.</p>
        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">2. Hosting</Typography>
        <p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</p>
        <h3>Amazon Web Services (AWS)</h3> <p>Anbieter ist die Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy,
        1855 Luxemburg (nachfolgend AWS).</p> <p>Wenn Sie unsere Website besuchen, werden Ihre personenbezogenen Daten
        auf den Servern von AWS verarbeitet. Hierbei k&ouml;nnen auch personenbezogene Daten an das Mutterunternehmen
        von AWS in die USA &uuml;bermittelt werden. Die Daten&uuml;bertragung in die USA wird auf die
        EU-Standardvertragsklauseln gest&uuml;tzt. Details finden Sie hier: <a
          href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/" target="_blank"
          rel="noopener noreferrer">https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/</a>.</p>
        <p>Weitere Informationen entnehmen Sie der Datenschutzerkl&auml;rung von AWS: <a
          href="https://aws.amazon.com/de/privacy/?nc1=f_pr" target="_blank"
          rel="noopener noreferrer">https://aws.amazon.com/de/privacy/?nc1=f_pr</a>.</p> <p>Die Verwendung von AWS
        erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer
        m&ouml;glichst zuverl&auml;ssigen Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt
        wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25
        Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im
        Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TDDDG umfasst. Die Einwilligung ist
        jederzeit widerrufbar.</p>
        <p>Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy
          Framework&ldquo; (DPF). Der DPF ist ein &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA,
          der die Einhaltung europ&auml;ischer Datenschutzstandards bei Datenverarbeitungen in den USA
          gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
          Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem
          Link: <a href="https://www.dataprivacyframework.gov/participant/5776" target="_blank"
                   rel="noopener noreferrer">https://www.dataprivacyframework.gov/participant/5776</a>.</p>
        <h4>Auftragsverarbeitung</h4> <p>Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur Nutzung des
        oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen
        Vertrag, der gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach
        unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.</p>
        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">3. Allgemeine Hinweise und Pflicht&shy;informationen</Typography>
        <h3>Datenschutz</h3> <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst.
        Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften
        sowie dieser Datenschutzerkl&auml;rung.</p> <p>Wenn Sie diese Website benutzen, werden verschiedene
        personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie pers&ouml;nlich identifiziert
        werden k&ouml;nnen. Die vorliegende Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und
        wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem Zweck das geschieht.</p> <p>Wir weisen
        darauf hin, dass die Daten&uuml;bertragung im Internet (z.&nbsp;B. bei der Kommunikation per E-Mail)
        Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist
        nicht m&ouml;glich.</p>
        <h3>Hinweis zur verantwortlichen Stelle</h3> <p>Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf
        dieser Website ist:</p> <p>Goran Antunovic<br/>
        Inh. Goran Antunovic<br/>
        Balkanski Mozaik<br/>
        Brucken&auml;cker 6<br/>
        72127 Kusterdingen</p>

        <p>Telefon: +49 7071 639 8294<br/>
          E-Mail: info@balkanskimozaik.de</p>
        <p>Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit
          anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen,
          E-Mail-Adressen o. &Auml;.) entscheidet.</p>

        <h3>Speicherdauer</h3> <p>Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere Speicherdauer
        genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck f&uuml;r die Datenverarbeitung
        entf&auml;llt. Wenn Sie ein berechtigtes L&ouml;schersuchen geltend machen oder eine Einwilligung zur
        Datenverarbeitung widerrufen, werden Ihre Daten gel&ouml;scht, sofern wir keine anderen rechtlich
        zul&auml;ssigen Gr&uuml;nde f&uuml;r die Speicherung Ihrer personenbezogenen Daten haben (z.&nbsp;B. steuer-
        oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die L&ouml;schung nach Fortfall
        dieser Gr&uuml;nde.</p>
        <h3>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</h3> <p>Sofern Sie in
        die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6
        Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO
        verarbeitet werden. Im Falle einer ausdr&uuml;cklichen Einwilligung in die &Uuml;bertragung personenbezogener
        Daten in Drittstaaten erfolgt die Datenverarbeitung au&szlig;erdem auf Grundlage von Art. 49 Abs. 1 lit. a
        DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr Endger&auml;t
        (z.&nbsp;B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zus&auml;tzlich auf
        Grundlage von &sect; 25 Abs. 1 TDDDG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
        Vertragserf&uuml;llung oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich, verarbeiten wir
        Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese
        zur Erf&uuml;llung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c
        DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
        DSGVO erfolgen. &Uuml;ber die jeweils im Einzelfall einschl&auml;gigen Rechtsgrundlagen wird in den folgenden
        Abs&auml;tzen dieser Datenschutzerkl&auml;rung informiert.</p>
        <h3>Datenschutz&shy;beauftragter</h3> <p>Wir haben einen Datenschutzbeauftragten benannt.</p>
        <p>Goran Antunovic<br/>
          Brucken&auml;cker 6<br/>
          72127 Kusterdingen</p>

        <p>Telefon: +49 7071 639 8294<br/>
          E-Mail: info@balkanskimozaik.de</p>
        <h3>Empfänger von personenbezogenen Daten</h3> <p>Im Rahmen unserer Gesch&auml;ftst&auml;tigkeit arbeiten wir
        mit verschiedenen externen Stellen zusammen. Dabei ist teilweise auch eine &Uuml;bermittlung von
        personenbezogenen Daten an diese externen Stellen erforderlich. Wir geben personenbezogene Daten nur dann an
        externe Stellen weiter, wenn dies im Rahmen einer Vertragserf&uuml;llung erforderlich ist, wenn wir gesetzlich
        hierzu verpflichtet sind (z.&nbsp;B. Weitergabe von Daten an Steuerbeh&ouml;rden), wenn wir ein berechtigtes
        Interesse nach Art. 6 Abs. 1 lit. f DSGVO an der Weitergabe haben oder wenn eine sonstige Rechtsgrundlage die
        Datenweitergabe erlaubt. Beim Einsatz von Auftragsverarbeitern geben wir personenbezogene Daten unserer Kunden
        nur auf Grundlage eines g&uuml;ltigen Vertrags &uuml;ber Auftragsverarbeitung weiter. Im Falle einer gemeinsamen
        Verarbeitung wird ein Vertrag &uuml;ber gemeinsame Verarbeitung geschlossen.</p>
        <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3> <p>Viele Datenverarbeitungsvorg&auml;nge sind nur mit
        Ihrer ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine bereits erteilte Einwilligung
        jederzeit widerrufen. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
        Widerruf unber&uuml;hrt.</p>
        <h3>Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen sowie gegen Direktwerbung (Art. 21
          DSGVO)</h3> <p>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN
        SIE JEDERZEIT DAS RECHT, AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
        VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH F&Uuml;R EIN AUF DIESE
        BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
        ENTNEHMEN SIE DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN
        PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE SCHUTZW&Uuml;RDIGE
        GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN &Uuml;BERWIEGEN
        ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN
        (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).</p> <p>WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM
        DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
        BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS
        PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE
        PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART.
        21 ABS. 2 DSGVO).</p>
        <h3>Beschwerde&shy;recht bei der zust&auml;ndigen Aufsichts&shy;beh&ouml;rde</h3> <p>Im Falle von
        Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde,
        insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des
        mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht besteht unbeschadet anderweitiger
        verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</p>
        <h3>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</h3> <p>Sie haben das Recht, Daten, die wir auf Grundlage
        Ihrer Einwilligung oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an sich oder an einen
        Dritten in einem g&auml;ngigen, maschinenlesbaren Format aush&auml;ndigen zu lassen. Sofern Sie die
        direkte &Uuml;bertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
        technisch machbar ist.</p>
        <h3>Auskunft, Berichtigung und L&ouml;schung</h3> <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
        jederzeit das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten personenbezogenen Daten, deren
        Herkunft und Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
        L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten k&ouml;nnen Sie
        sich jederzeit an uns wenden.</p>
        <h3>Recht auf Einschr&auml;nkung der Verarbeitung</h3> <p>Sie haben das Recht, die Einschr&auml;nkung der
        Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich jederzeit an uns wenden.
        Das Recht auf Einschr&auml;nkung der Verarbeitung besteht in folgenden F&auml;llen:</p>
        <ul>
          <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, ben&ouml;tigen
            wir in der Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der Pr&uuml;fung haben Sie das
            Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
          <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen
            Sie statt der L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung verlangen.
          </li>
          <li>Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie jedoch zur Aus&uuml;bung,
            Verteidigung oder Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der
            L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
          <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abw&auml;gung zwischen
            Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen
            Interessen &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen.
          </li>
        </ul>
        <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben, d&uuml;rfen diese
          Daten &ndash; von ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur Geltendmachung,
          Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
          nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen Interesses
          der Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet werden.</p>
        <h3>SSL- bzw. TLS-Verschl&uuml;sselung</h3> <p>Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz
        der &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als
        Seitenbetreiber senden, eine SSL- bzw. TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen
        Sie daran, dass die Adresszeile des Browsers von &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und
        an dem Schloss-Symbol in Ihrer Browserzeile.</p> <p>Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist,
        k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen werden.</p>
        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">4. Datenerfassung auf dieser Website</Typography>
        <h3>Cookies</h3> <p>Unsere Internetseiten verwenden so genannte &bdquo;Cookies&ldquo;. Cookies sind kleine
        Datenpakete und richten auf Ihrem Endger&auml;t keinen Schaden an. Sie werden entweder vor&uuml;bergehend
        f&uuml;r die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endger&auml;t
        gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gel&ouml;scht. Permanente Cookies
        bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie diese selbst l&ouml;schen oder eine automatische
        L&ouml;schung durch Ihren Webbrowser erfolgt.</p> <p>Cookies k&ouml;nnen von uns (First-Party-Cookies) oder von
        Drittunternehmen stammen (sog. Third-Party-Cookies). Third-Party-Cookies erm&ouml;glichen die Einbindung
        bestimmter Dienstleistungen von Drittunternehmen innerhalb von Webseiten (z.&nbsp;B. Cookies zur Abwicklung von
        Zahlungsdienstleistungen).</p> <p>Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch
        notwendig, da bestimmte Webseitenfunktionen ohne diese nicht funktionieren w&uuml;rden (z.&nbsp;B. die
        Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies k&ouml;nnen zur Auswertung des Nutzerverhaltens
        oder zu Werbezwecken verwendet werden.</p> <p>Cookies, die zur Durchf&uuml;hrung des elektronischen
        Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen erw&uuml;nschter Funktionen (z.&nbsp;B.
        f&uuml;r die Warenkorbfunktion) oder zur Optimierung der Website (z.&nbsp;B. Cookies zur Messung des
        Webpublikums) erforderlich sind (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO
        gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes
        Interesse an der Speicherung von notwendigen Cookies zur technisch fehlerfreien und optimierten Bereitstellung
        seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und vergleichbaren
        Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage dieser
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TDDDG); die Einwilligung ist jederzeit
        widerrufbar.</p> <p>Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das Setzen von Cookies
        informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies f&uuml;r bestimmte F&auml;lle
        oder generell ausschlie&szlig;en sowie das automatische L&ouml;schen der Cookies beim Schlie&szlig;en des
        Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalit&auml;t dieser Website
        eingeschr&auml;nkt sein.</p> <p>Welche Cookies und Dienste auf dieser Website eingesetzt werden, k&ouml;nnen Sie
        dieser Datenschutzerkl&auml;rung entnehmen.</p>
        <h3>Einwilligung mit Usercentrics</h3> <p>Diese Website nutzt die Consent-Technologie von Usercentrics, um Ihre
        Einwilligung zur Speicherung bestimmter Cookies auf Ihrem Endger&auml;t oder zum Einsatz bestimmter Technologien
        einzuholen und diese datenschutzkonform zu dokumentieren. Anbieter dieser Technologie ist die Usercentrics GmbH,
        Sendlinger Stra&szlig;e 7, 80331 M&uuml;nchen, Website: <a href="https://usercentrics.com/de/" target="_blank"
                                                                   rel="noopener noreferrer">https://usercentrics.com/de/</a> (im
        Folgenden &bdquo;Usercentrics&ldquo;).</p> <p>Wenn Sie unsere Website betreten, werden folgende personenbezogene
        Daten an Usercentrics &uuml;bertragen:</p>
        <ul>
          <li>Ihre Einwilligung(en) bzw. der Widerruf Ihrer Einwilligung(en)</li>
          <li>Ihre IP-Adresse</li>
          <li>Informationen &uuml;ber Ihren Browser</li>
          <li>Informationen &uuml;ber Ihr Endger&auml;t</li>
          <li>Zeitpunkt Ihres Besuchs auf der Website</li>
          <li>Geolocation</li>
        </ul>
        <p>Des Weiteren speichert Usercentrics ein Cookie in Ihrem Browser, um Ihnen die erteilten Einwilligungen bzw.
          deren Widerruf zuordnen zu k&ouml;nnen. Die so erfassten Daten werden gespeichert, bis Sie uns zur
          L&ouml;schung auffordern, das Usercentrics-Cookie selbst l&ouml;schen oder der Zweck f&uuml;r die
          Datenspeicherung entf&auml;llt. Zwingende gesetzliche Aufbewahrungspflichten bleiben unber&uuml;hrt.</p>
        <p>Das Usercentrics-Banner auf dieser Website wurde mit Hilfe von eRecht24 konfiguriert. Das erkennen Sie daran,
          dass im Banner das Logo von eRecht24 auftaucht. Um das eRecht24-Logo im Banner auszuspielen, wird eine
          Verbindung zum Bildserver von eRecht24 hergestellt. Hierbei wird auch die IP-Adresse &uuml;bertragen, die
          jedoch nur in anonymisierter Form in den Server-Logs gespeichert wird. Der Bildserver von eRecht24 befindet
          sich in Deutschland bei einem deutschen Anbieter. Das Banner selbst wird ausschlie&szlig;lich von Usercentrics
          zur Verf&uuml;gung gestellt.</p>
        <p>Der Einsatz von Usercentrics erfolgt, um die gesetzlich vorgeschriebenen Einwilligungen f&uuml;r den Einsatz
          bestimmter Technologien einzuholen. Rechtsgrundlage hierf&uuml;r ist Art. 6 Abs. 1 lit. c DSGVO.</p>

        <h4>Auftragsverarbeitung</h4> <p>Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur Nutzung des
        oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen
        Vertrag, der gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach
        unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.</p>
        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">5. Soziale Medien</Typography>
        <h3>Facebook</h3> <p>Auf dieser Website sind Elemente des sozialen Netzwerks Facebook integriert. Anbieter
        dieses Dienstes ist die Meta Platforms Ireland Limited, Merrion Road, Dublin 4, D04 X2K5, Irland. Die erfassten
        Daten werden nach Aussage von Facebook jedoch auch in die USA und in andere
        Drittl&auml;nder &uuml;bertragen.</p> <p>Eine &Uuml;bersicht &uuml;ber die Facebook Social-Media-Elemente finden
        Sie hier: <a href="https://developers.facebook.com/docs/plugins/?locale=de_DE" target="_blank"
                     rel="noopener noreferrer">https://developers.facebook.com/docs/plugins/?locale=de_DE</a>.</p>
        <p>Wenn das Social-Media-Element aktiv ist, wird eine direkte Verbindung zwischen Ihrem Endger&auml;t und dem
          Facebook-Server hergestellt. Facebook erh&auml;lt dadurch die Information, dass Sie mit Ihrer IP-Adresse diese
          Website besucht haben. Wenn Sie den Facebook &bdquo;Like-Button&ldquo; anklicken, w&auml;hrend Sie in Ihrem
          Facebook-Account eingeloggt sind, k&ouml;nnen Sie die Inhalte dieser Website auf Ihrem Facebook-Profil
          verlinken. Dadurch kann Facebook den Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf
          hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der &uuml;bermittelten Daten sowie deren
          Nutzung durch Facebook erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerkl&auml;rung von
          Facebook unter: <a href="https://de-de.facebook.com/privacy/explanation" target="_blank"
                             rel="noopener noreferrer">https://de-de.facebook.com/privacy/explanation</a>.</p> <p>Die
        Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25
        Abs. 1 TDDDG. Die Einwilligung ist jederzeit widerrufbar.</p> <p>Soweit mit Hilfe des hier beschriebenen Tools
        personenbezogene Daten auf unserer Website erfasst und an Facebook weitergeleitet werden, sind wir und die Meta
        Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland gemeinsam f&uuml;r diese
        Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die gemeinsame Verantwortlichkeit beschr&auml;nkt sich dabei
        ausschlie&szlig;lich auf die Erfassung der Daten und deren Weitergabe an Facebook. Die nach der Weiterleitung
        erfolgende Verarbeitung durch Facebook ist nicht Teil der gemeinsamen Verantwortung. Die uns gemeinsam
        obliegenden Verpflichtungen wurden in einer Vereinbarung &uuml;ber gemeinsame Verarbeitung festgehalten. Den
        Wortlaut der Vereinbarung finden Sie unter: <a href="https://www.facebook.com/legal/controller_addendum"
                                                       target="_blank"
                                                       rel="noopener noreferrer">https://www.facebook.com/legal/controller_addendum</a>.
        Laut dieser Vereinbarung sind wir f&uuml;r die Erteilung der Datenschutzinformationen beim Einsatz des
        Facebook-Tools und f&uuml;r die datenschutzrechtlich sichere Implementierung des Tools auf unserer Website
        verantwortlich. F&uuml;r die Datensicherheit der Facebook-Produkte ist Facebook verantwortlich.
        Betroffenenrechte (z.&nbsp;B. Auskunftsersuchen) hinsichtlich der bei Facebook verarbeiteten Daten k&ouml;nnen
        Sie direkt bei Facebook geltend machen. Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir
        verpflichtet, diese an Facebook weiterzuleiten.</p> <p>Die Daten&uuml;bertragung in die USA wird auf die
        Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden Sie hier: <a
          href="https://www.facebook.com/legal/EU_data_transfer_addendum" target="_blank"
          rel="noopener noreferrer">https://www.facebook.com/legal/EU_data_transfer_addendum</a>, <a
          href="https://de-de.facebook.com/help/566994660333381" target="_blank"
          rel="noopener noreferrer">https://de-de.facebook.com/help/566994660333381</a> und <a
          href="https://www.facebook.com/policy.php" target="_blank"
          rel="noopener noreferrer">https://www.facebook.com/policy.php</a>.</p>
        <p>Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy
          Framework&ldquo; (DPF). Der DPF ist ein &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA,
          der die Einhaltung europ&auml;ischer Datenschutzstandards bei Datenverarbeitungen in den USA
          gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
          Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem
          Link: <a href="https://www.dataprivacyframework.gov/participant/4452" target="_blank"
                   rel="noopener noreferrer">https://www.dataprivacyframework.gov/participant/4452</a>.</p>
        <h3>X (ehemals Twitter)</h3> <p>Auf dieser Website sind Funktionen des Dienstes X (ehemals Twitter) eingebunden.
        Diese Funktionen werden angeboten durch den Mutterkonzern X Corp., 1355 Market Street, Suite 900, San Francisco,
        CA 94103, USA. F&uuml;r die Datenverarbeitung von au&szlig;erhalb der USA lebenden Personen ist die
        Niederlassung Twitter International Unlimited Company, One Cumberland Place, Fenian Street, Dublin 2, D02 AX07,
        Irland, verantwortlich.</p> <p>Wenn das Social-Media-Element aktiv ist, wird eine direkte Verbindung zwischen
        Ihrem Endger&auml;t und dem X-Server hergestellt. X (ehemals Twitter) erh&auml;lt dadurch
        Informationen &uuml;ber den Besuch dieser Website durch Sie. Durch das Benutzen von X (ehemals Twitter) und der
        Funktion &bdquo;Re-Tweet&ldquo; bzw. &bdquo;Repost&ldquo; werden die von Ihnen besuchten Websites mit Ihrem X
        (ehemals Twitter)-Account verkn&uuml;pft und anderen Nutzern bekannt gegeben. Wir weisen darauf hin, dass wir
        als Anbieter der Seiten keine Kenntnis vom Inhalt der &uuml;bermittelten Daten sowie deren Nutzung durch X
        (ehemals Twitter) erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerkl&auml;rung von X
        (ehemals Twitter) unter: <a href="https://x.com/de/privacy" target="_blank"
                                    rel="noopener noreferrer">https://x.com/de/privacy</a>.</p> <p>Die Nutzung dieses
        Dienstes erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TDDDG.
        Die Einwilligung ist jederzeit widerrufbar.</p> <p>Die Daten&uuml;bertragung in die USA wird auf die
        Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden Sie hier: <a
          href="https://gdpr.x.com/en/controller-to-controller-transfers.html" target="_blank"
          rel="noopener noreferrer">https://gdpr.x.com/en/controller-to-controller-transfers.html</a>.</p> <p>Ihre
        Datenschutzeinstellungen bei X (ehemals Twitter) k&ouml;nnen Sie in den Konto-Einstellungen unter <a
          href="https://x.com/settings/account" target="_blank"
          rel="noopener noreferrer">https://x.com/settings/account</a> &auml;ndern.</p>
        <p>Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy
          Framework&ldquo; (DPF). Der DPF ist ein &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA,
          der die Einhaltung europ&auml;ischer Datenschutzstandards bei Datenverarbeitungen in den USA
          gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
          Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem
          Link: <a href="https://www.dataprivacyframework.gov/participant/2710" target="_blank"
                   rel="noopener noreferrer">https://www.dataprivacyframework.gov/participant/2710</a>.</p>
        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">6. Analyse-Tools und Werbung</Typography>
        <h3>Google Analytics</h3> <p>Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter
        ist die Google Ireland Limited (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4, Irland.</p>
        <p>Google Analytics erm&ouml;glicht es dem Websitebetreiber, das Verhalten der Websitebesucher zu analysieren.
          Hierbei erh&auml;lt der Websitebetreiber verschiedene Nutzungsdaten, wie z.&nbsp;B. Seitenaufrufe,
          Verweildauer, verwendete Betriebssysteme und Herkunft des Nutzers. Diese Daten werden in einer User-ID
          zusammengefasst und dem jeweiligen Endger&auml;t des Websitebesuchers zugeordnet.</p> <p>Des Weiteren
        k&ouml;nnen wir mit Google Analytics u.&nbsp;a. Ihre Maus- und Scrollbewegungen und Klicks aufzeichnen. Ferner
        verwendet Google Analytics verschiedene Modellierungsans&auml;tze, um die erfassten Datens&auml;tze zu
        erg&auml;nzen und setzt Machine-Learning-Technologien bei der Datenanalyse ein.</p>
        <p>Google Analytics verwendet Technologien, die die Wiedererkennung des Nutzers zum Zwecke der Analyse des
          Nutzerverhaltens erm&ouml;glichen (z.&nbsp;B. Cookies oder Device-Fingerprinting). Die von Google erfassten
          Informationen &uuml;ber die Benutzung dieser Website werden in der Regel an einen Server von Google in den
          USA &uuml;bertragen und dort gespeichert.</p> <p>Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer
        Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TDDDG. Die Einwilligung ist jederzeit
        widerrufbar.</p> <p>Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission
        gest&uuml;tzt. Details finden Sie hier: <a href="https://privacy.google.com/businesses/controllerterms/mccs/"
                                                   target="_blank"
                                                   rel="noopener noreferrer">https://privacy.google.com/businesses/controllerterms/mccs/</a>.
      </p> <p>Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy
        Framework&ldquo; (DPF). Der DPF ist ein &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA, der
        die Einhaltung europ&auml;ischer Datenschutzstandards bei Datenverarbeitungen in den USA gew&auml;hrleisten
        soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten.
        Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link: <a
          href="https://www.dataprivacyframework.gov/participant/5780" target="_blank"
          rel="noopener noreferrer">https://www.dataprivacyframework.gov/participant/5780</a>.</p>
        <h4>IP Anonymisierung</h4> <p>Die Google Analytics IP-Anonymisierung ist aktiviert. Dadurch wird Ihre IP-Adresse
        von Google innerhalb von Mitgliedstaaten der Europ&auml;ischen Union oder in anderen Vertragsstaaten des
        Abkommens &uuml;ber den Europ&auml;ischen Wirtschaftsraum vor der &Uuml;bermittlung in die USA gek&uuml;rzt. Nur
        in Ausnahmef&auml;llen wird die volle IP-Adresse an einen Server von Google in den USA &uuml;bertragen und dort
        gek&uuml;rzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung
        der Website auszuwerten, um Reports &uuml;ber die Websiteaktivit&auml;ten zusammenzustellen und um weitere mit
        der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegen&uuml;ber dem Websitebetreiber zu
        erbringen. Die im Rahmen von Google Analytics von Ihrem Browser &uuml;bermittelte IP-Adresse wird nicht mit
        anderen Daten von Google zusammengef&uuml;hrt.</p>
        <h4>Browser Plugin</h4> <p>Sie k&ouml;nnen die Erfassung und Verarbeitung Ihrer Daten durch Google verhindern,
        indem Sie das unter dem folgenden Link verf&uuml;gbare Browser-Plugin herunterladen und installieren: <a
          href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"
          rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de</a>.</p> <p>Mehr Informationen zum
        Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerkl&auml;rung von Google: <a
          href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank"
          rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=de</a>.</p>
        <h4>Google-Signale</h4> <p>Wir nutzen Google-Signale. Wenn Sie unsere Website besuchen, erfasst Google Analytics
        u.&nbsp;a. Ihren Standort, Suchverlauf und YouTube-Verlauf sowie demografische Daten (Besucherdaten). Diese
        Daten k&ouml;nnen mit Hilfe von Google-Signal f&uuml;r personalisierte Werbung verwendet werden. Wenn
        Sie &uuml;ber ein Google-Konto verf&uuml;gen, werden die Besucherdaten von Google-Signal mit Ihrem Google-Konto
        verkn&uuml;pft und f&uuml;r personalisierte Werbebotschaften verwendet. Die Daten werden au&szlig;erdem f&uuml;r
        die Erstellung anonymisierter Statistiken zum Nutzerverhalten unserer User verwendet.</p>
        <h4>Auftragsverarbeitung</h4> <p>Wir haben mit Google einen Vertrag zur Auftragsverarbeitung abgeschlossen und
        setzen die strengen Vorgaben der deutschen Datenschutzbeh&ouml;rden bei der Nutzung von Google Analytics
        vollst&auml;ndig um.</p>
        <h4>Google Analytics E-Commerce-Messung</h4> <p>Diese Website nutzt die
        Funktion &bdquo;E-Commerce-Messung&ldquo; von Google Analytics. Mit Hilfe von E-Commerce-Messung kann der
        Websitebetreiber das Kaufverhalten der Websitebesucher zur Verbesserung seiner Online-Marketing-Kampagnen
        analysieren. Hierbei werden Informationen, wie zum Beispiel die get&auml;tigten Bestellungen, durchschnittliche
        Bestellwerte, Versandkosten und die Zeit von der Ansicht bis zum Kauf eines Produktes erfasst. Diese Daten
        k&ouml;nnen von Google unter einer Transaktions-ID zusammengefasst werden, die dem jeweiligen Nutzer bzw. dessen
        Ger&auml;t zugeordnet ist.</p>
        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">7. Plugins und Tools</Typography>
        <h3>YouTube mit erweitertem Datenschutz</h3> <p>Diese Website bindet Videos der Website YouTube ein. Betreiber
        der Website ist die Google Ireland Limited (&bdquo;Google&rdquo;), Gordon House, Barrow Street, Dublin 4,
        Irland.</p> <p>Wenn Sie eine dieser Website besuchen, auf denen YouTube eingebunden ist, wird eine Verbindung zu
        den Servern von YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht
        haben. Wenn Sie in Ihrem YouTube-Account eingeloggt sind, erm&ouml;glichen Sie YouTube, Ihr Surfverhalten direkt
        Ihrem pers&ouml;nlichen Profil zuzuordnen. Dies k&ouml;nnen Sie verhindern, indem Sie sich aus Ihrem
        YouTube-Account ausloggen.</p> <p>Wir nutzen YouTube im erweiterten Datenschutzmodus. Videos, die im erweiterten
        Datenschutzmodus abgespielt werden, werden nach Aussage von YouTube nicht zur Personalisierung des Surfens auf
        YouTube eingesetzt. Anzeigen, die im erweiterten Datenschutzmodus ausgespielt werden, sind ebenfalls nicht
        personalisiert. Im erweiterten Datenschutzmodus werden keine Cookies gesetzt. Stattdessen werden jedoch
        sogenannte Local Storage Elemente im Browser des Users gespeichert, die &auml;hnlich wie Cookies
        personenbezogene Daten beinhalten und zur Wiedererkennung eingesetzt werden k&ouml;nnen. Details zum erweiterten
        Datenschutzmodus finden Sie hier: <a href="https://support.google.com/youtube/answer/171780" target="_blank"
                                             rel="noopener noreferrer">https://support.google.com/youtube/answer/171780</a>.
      </p> <p>Gegebenenfalls k&ouml;nnen nach der Aktivierung eines YouTube-Videos weitere
        Datenverarbeitungsvorg&auml;nge ausgel&ouml;st werden, auf die wir keinen Einfluss haben.</p> <p>Die Nutzung von
        YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein
        berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
        abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO
        und &sect; 25 Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
        Informationen im Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TDDDG umfasst. Die
        Einwilligung ist jederzeit widerrufbar.</p> <p>Weitere Informationen &uuml;ber Datenschutz bei YouTube finden
        Sie in deren Datenschutzerkl&auml;rung unter: <a href="https://policies.google.com/privacy?hl=de"
                                                         target="_blank"
                                                         rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.
      </p>
        <p>Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy
          Framework&ldquo; (DPF). Der DPF ist ein &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA,
          der die Einhaltung europ&auml;ischer Datenschutzstandards bei Datenverarbeitungen in den USA
          gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
          Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem
          Link: <a href="https://www.dataprivacyframework.gov/participant/5780" target="_blank"
                   rel="noopener noreferrer">https://www.dataprivacyframework.gov/participant/5780</a>.</p>
        <h3>Google Fonts</h3> <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Google
        Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten
        Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.</p> <p>Zu diesem Zweck muss der von
        Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis
        dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse diese Website aufgerufen wurde. Die Nutzung von Google Fonts
        erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
        einheitlichen Darstellung des Schriftbildes auf seiner Website. Sofern eine entsprechende Einwilligung abgefragt
        wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25
        Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im
        Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TDDDG umfasst. Die Einwilligung ist
        jederzeit widerrufbar.</p> <p>Wenn Ihr Browser Google Fonts nicht unterst&uuml;tzt, wird eine Standardschrift
        von Ihrem Computer genutzt.</p> <p>Weitere Informationen zu Google Fonts finden Sie unter <a
        href="https://developers.google.com/fonts/faq" target="_blank"
        rel="noopener noreferrer">https://developers.google.com/fonts/faq</a> und in der Datenschutzerkl&auml;rung von
        Google: <a href="https://policies.google.com/privacy?hl=de" target="_blank"
                   rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
        <p>Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy
          Framework&ldquo; (DPF). Der DPF ist ein &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA,
          der die Einhaltung europ&auml;ischer Datenschutzstandards bei Datenverarbeitungen in den USA
          gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
          Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem
          Link: <a href="https://www.dataprivacyframework.gov/participant/5780" target="_blank"
                   rel="noopener noreferrer">https://www.dataprivacyframework.gov/participant/5780</a>.</p>
        <h3>Font Awesome (lokales Hosting)</h3> <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten Font
        Awesome. Font Awesome ist lokal installiert. Eine Verbindung zu Servern von Fonticons, Inc. findet dabei nicht
        statt.</p> <p>Weitere Informationen zu Font Awesome finden Sie in der Datenschutzerkl&auml;rung f&uuml;r Font
        Awesome unter: <a href="https://fontawesome.com/privacy" target="_blank"
                          rel="noopener noreferrer">https://fontawesome.com/privacy</a>.</p>

      </div>
    </>
  );
}
