import {fetchArticles} from '../../services/articleService';
import CategorySidebarSection from '../../components/category-sidebar-section/CategorySidebarSection';

export default function ArticlePageSideContent({className}) {

  const predefinedCategories = [
    {
      title: 'Neueste',
      category: 'newest',
      titleUrl: '/',
      fetchArticles: () => fetchArticles({limit: 5}).then(data => data.docs)
    }
  ];

  return (
    <div className={`${className || ''}`}>
      {predefinedCategories.map(({title, titleUrl, category, fetchArticles}) => (
        <CategorySidebarSection key={category} title={title} titleUrl={titleUrl} fetchArticles={() => fetchArticles()}/>
      ))}
    </div>
  );
}
