import React, {useState, useEffect, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';
import {searchArticles} from '../../services/articleService';
import MetaComponent from '../../components/common/MetaComponent';
import {
  FeaturedCategoryArticlesSkeleton
} from '../../components/featured-category-articles/FeaturedCategoryArticles';
import ArticlesGrid from '../../components/articles-grid/ArticlesGrid';
import {Typography} from '@material-tailwind/react';

export default function Search() {
  const [searchParams] = useSearchParams();

  const metadata = {
    title: `Suche - Balkanski Mozaik`
  };

  const articlesGrid = useMemo(() => {
    if (!searchParams) return null;
    return (
      <ArticlesGrid
        fetchArticles={searchArticles}
        fetchArticleArgs={{query: searchParams.get('q'), limit: 5}}
      />
    );
  }, [searchParams]);

  return (
    <>
      <MetaComponent meta={metadata}/>
      <div className="pt-6 pb-4">
        <h2 className="mb-3 font-semibold text-3xl">Suche nach "{searchParams.get('q')}"</h2>
        {articlesGrid}
      </div>
    </>
  );
}
